import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage, domStorage } from '../_globals'
import TableOfContents from '../modules/table-of-contents'
import WknSwiper from '../modules/wkn-swiper'

export default class PageCustomPassword extends Renderer {
  
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    // DOM
    this.DOM = {
      contents: viewStorage.current.querySelector('.contents'),
      tableOfContentsButton: viewStorage.current.querySelector('#TOC-opener'),
      tableOfContentsUl: viewStorage.current.querySelector('.table-of-contents ul'),
      allSwipers: [...viewStorage.current.querySelectorAll('.swiper')]
    }

    // Hide Header
    domStorage.header.classList.add('--hidden')
    domStorage.footer.classList.add('--hidden')

    // Table of contents
    if (this.DOM.contents || this.DOM.tableOfContentsUl) {
      this.toc = new TableOfContents({
        button: this.DOM.tableOfContentsButton,
        element: this.DOM.tableOfContentsUl,
        section: this.DOM.contents
      })
    }

    // Swipers
    this.swipers = []
    
    if (this.DOM.allSwipers.length) {
      this.DOM.allSwipers.forEach(s => {
        const swiper = new WknSwiper(s, {
          slidesPerView: 'auto'
        })
        this.swipers.push(swiper)
      })
    }
  }

  onLeaveCompleted() {
    domStorage.header.classList.remove('--hidden')
    domStorage.footer.classList.remove('--hidden')

    if (this.toc) {
      this.toc.destroy()
    }

    if (this.swipers.length) {
      this.swipers.forEach(s => s.destroy())
    }
  }
}
